type IgExperiment = {
  id: string;
  name: string;
  isPreview: boolean;
};

type IgTestGroup = {
  id: string;
  name: string;
  percentage: number;
  isControl: boolean;
  freeShippingThreshold?: number;
  shippingRate?: number;
};

type IgData = {
  user: {
    getExperiments: () => IgExperiment[];
    getTestGroup: (id: string) => IgTestGroup | null;
  };
};

/**
 * Calls the callback function with the igData object when it is available.
 * This is the entry point to work with the Intelligems JS API.
 *
 * @see {@link https://docs.intelligems.io/developer-resources/javascript-api}
 * @param callback - the callback function
 * @returns void
 */
export function withIgData(callback: (igData: IgData) => void) {
  const w = window as unknown as Window & {igData?: IgData};
  if (w.igData) {
    return callback(w.igData);
  }
  document.addEventListener('ig:ready', () => w.igData && callback(w.igData));
}
