import {getCartHandler, updateCartHandler} from '@/cart/cart-api';
import {logError, logInfo} from '@/utilities/log';
import {prefix} from './feature-flags-store';

/**
 * Retrieves the feature flags from the cart attributes.
 * @returns A map of feature flags.
 */
export async function getCartAttributeFeatureFlags() {
  try {
    const {attributes} = await getCartHandler();
    const flags = new Map<string, string>();
    for (const [key, value] of Object.entries(attributes)) {
      if (!key.startsWith(prefix)) continue;
      flags.set(key.replace(prefix, ''), value);
    }
    logInfo('FeatureFlagsFromCartAttributes', {
      flags: Object.fromEntries(flags.entries()),
    });
    return flags;
  } catch (error) {
    logError(error, {message: 'ErrorGettingFeatureFlagsFromCartAttributes'});
    return new Map<string, string>();
  }
}

/**
 * Sets the feature flags in the cart attributes to an empty string.
 * This is used to clear the feature flags from the cart,
 * so that the feature flags from the URL parameters can be evaluated.
 * @param flags - A map of feature flags to clear from the cart attributes.
 */
export async function clearCartAttributeFeatureFlags(
  flags: Map<string, string>
) {
  await updateCartAttributeFeatureFlags(
    new Map(Array.from(flags.entries()).map(([key]) => [key, ''] as const))
  );
  logInfo('FeatureFlagsClearedFromCartAttributes', {
    flags: Object.fromEntries(flags.entries()),
  });
}

/**
 * Updates the feature flag attributes in the cart based on the provided flags.
 * Only updates the flags that have changed.
 * If no flags have changed, the cart is not updated.
 *
 * @param flags - A map of feature flags, where the key is the flag name and the value is the flag value.
 * @returns Promise<void>
 */
export async function updateCartAttributeFeatureFlags(
  flags: Map<string, string>
) {
  try {
    const {attributes} = await getCartHandler();

    // Only update the flags that have changed
    const updatedFlags = Array.from(
      flags,
      ([key, value]) => [`${prefix}${key}`, value] as [string, string]
    ).filter(([key, value]) => attributes[key] !== value);

    // Don't update the cart if no flags have changed
    if (updatedFlags.length === 0) return;

    await updateCartHandler({
      attributes: Object.fromEntries(updatedFlags),
    });
    logInfo('FeatureFlagsUpdatedInCartAttributes', {
      flags: Object.fromEntries(updatedFlags),
    });
  } catch (error) {
    logError(error, {message: 'ErrorUpdatingFeatureFlagsInCartAttributes'});
  }
}
