import {CustomerPrivacy, ShopifyGlobalWindow} from './types';

/**
 * Get the user's current tracking consent status from Shopify's customer privacy API.
 * @param customerPrivacy - Shopify global customer privacy objects
 * @returns 'granted' if the user has consented to tracking, 'not-granted' otherwise
 */
export function getTrackingConsent(customerPrivacy: CustomerPrivacy) {
  const trackingConsent = customerPrivacy.userCanBeTracked()
    ? 'granted'
    : 'not-granted';
  return trackingConsent;
}

/**
 * Determine whether it is necessary to request tracking consent from the user.
 * In certain regions, such as the EU and California,
 * it is required to obtain user consent before collecting analytics data.
 *
 * @param customerPrivacy - Shopify global customer privacy object
 * @returns true if the user has not consented to tracking, false otherwise
 */
export function isConsentRequired(customerPrivacy: CustomerPrivacy) {
  return customerPrivacy.shouldShowBanner();
}

/**
 * Get the Shopify global customer privacy object.
 *
 * @returns Shopify global customer privacy object
 */
export function getCustomerPrivacy() {
  return (window as unknown as ShopifyGlobalWindow).Shopify.customerPrivacy;
}
