import {
  clearCartAttributeFeatureFlags,
  getCartAttributeFeatureFlags,
  updateCartAttributeFeatureFlags,
} from '@/feature-flags/cart-attributes';
import {setFeatureFlags} from '@/feature-flags/feature-flags-store';
import {
  clearUrlFeatureFlags,
  getUrlFeatureFlags,
} from '@/feature-flags/url-params';
import {logError, logInfo} from '@/utilities/log';

/**
 * Evaluates and updates the feature flags based on the cart attributes and URL parameters.
 * This function reads the persisted feature flags from the cart attributes, updates them with
 * any feature flags present in the URL parameters, and then writes the evaluated feature flags
 * to the store. It also clears the URL feature flags and syncs the cart attributes with the
 * updated feature flags.
 *
 * @returns A promise that resolves when the feature flags have been evaluated and updated.
 */
export async function evaluateFeatureFlags() {
  try {
    const cartFlags = await getCartAttributeFeatureFlags();
    const urlFlags = getUrlFeatureFlags();
    const activeFlags = selectActiveFlags(urlFlags, cartFlags);
    if (!activeFlags) {
      logInfo('NoActiveFeatureFlag');
      return;
    }

    setFeatureFlags(activeFlags);
    // only one feature flag can be active, so we can clear the cart flags before updating
    // the cart with the new flag
    if (cartFlags.size > 0) {
      await clearCartAttributeFeatureFlags(cartFlags);
    }
    await updateCartAttributeFeatureFlags(activeFlags);
    clearUrlFeatureFlags();

    logInfo('EvaluatedFeatureFlags', {
      flags: Object.fromEntries(activeFlags.entries()),
    });
  } catch (error) {
    logError(error, {message: 'ErrorEvaluatingFeatureFlags'});
  }
}

/**
 * Selects the active flag from the new and old flags.
 * If there are no new flags, the old flags are used.
 * If there are no old flags, nothing is returned.
 * This allows us to determine which flag is active,
 * and clear all previously active feature flags.
 *
 * @param newFlags - the new flags acquired from the url
 * @param oldFlags - the old flags acquired from the cart attributes
 * @returns the active flag
 */
function selectActiveFlags(
  newFlags: Map<string, string>,
  oldFlags: Map<string, string>
): Map<string, string> | undefined {
  if (newFlags.size > 0) {
    return newFlags;
  }
  if (oldFlags.size > 0) {
    return oldFlags;
  }
}
