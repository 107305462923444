import type {RumInitConfiguration} from '@datadog/browser-rum';

const DD_APPLICATION_ID = import.meta.env.VITE_DD_APPLICATION_ID as string;
const DD_CLIENT_TOKEN = import.meta.env.VITE_DD_CLIENT_TOKEN as string;
const DD_SITE = import.meta.env.VITE_DD_SITE as string;
const DD_ENV = import.meta.env.VITE_DD_ENV as string;

if (!DD_APPLICATION_ID || !DD_CLIENT_TOKEN || !DD_SITE || !DD_ENV) {
  throw new Error('Datadog RUM environment variables are not set');
}

/**
 * Configuration object for initializing Datadog Real User Monitoring (RUM).
 *
 * @remarks
 * This object contains various properties that can be used to configure the behavior of the Datadog RUM agent.
 * It is exported as the default export of this module.
 *
 */
export default {
  allowFallbackToLocalStorage: true,
  applicationId: DD_APPLICATION_ID,
  clientToken: DD_CLIENT_TOKEN,
  defaultPrivacyLevel: 'mask-user-input',
  env: DD_ENV,
  service: 'shopify-theme',
  sessionReplaySampleRate: 40,
  sessionSampleRate: 100,
  site: DD_SITE,
  telemetrySampleRate: 0,
  traceSampleRate: 100,
  trackLongTasks: true,
  trackResources: true,
  trackSessionAcrossSubdomains: true,
  trackUserInteractions: true,
  enableExperimentalFeatures: ['feature_flags'],
  // Specify a version number to identify the deployed version of your application in Datadog
  // version: '1.0.0',
} satisfies RumInitConfiguration;
